html {
  font-family: Roboto, "Helvetica Neue", sans-serif;
}

input[type="color"],
input[type="date"],
input[type="datetime"],
input[type="datetime-local"],
input[type="email"],
input[type="month"],
input[type="number"],
input[type="password"],
input[type="search"],
input[type="tel"],
input[type="text"],
input[type="time"],
input[type="url"],
input[type="week"],
select:focus,
textarea {
  font-size: 16px;
}

body {
  margin: 0;
}

::ng-deep *:disabled {
  user-select: initial !important;
}

::ng-deep .small-button {
  line-height: 25px;
  height: 25px;
}

::ng-deep .success-snack {
  background-color: forestgreen !important;
  color: white !important;
}

::ng-deep .error-snack {
  background-color: #d20000 !important;
  color: white !important;
}

::ng-deep .trouble-snack {
  background-color: orange !important;
  color: white !important;
}

.search-autocomplete {

  max-height: 500px !important;

  mat-option {
    line-height: 35px;
    height: 35px;
  }

  a {
    text-decoration: initial !important;
    color: initial !important;

    .mat-list-item-content {
      display: flex;
      justify-content: flex-start;
      align-items: center;
    }
  }

  img {
    margin-right: 5px;
  }
}

.br-support-menu {
  button {
    line-height: 20px;
    margin-bottom: 14px;
    text-align: left;
    width: 100%;
  }
}

.match-editor-functions-menu {
  .mat-menu-content {
    display: flex;
    flex-flow: column;
    padding: 12px;
  }
}

::ng-deep {

  .date-filter-created-info {
    float: right;
    width: 130px;
    text-align: right;

    span {
      font-size: 12px;
      color: gray;
    }
  }

  .options-icon {
    height: 16px;
    width: 16px;
  }

  .mat-select-panel {
    max-height: 500px !important;
  }

  mat-card {
    box-sizing: border-box;
    margin: 8px 8px 16px 8px !important;
    padding: 8px 16px 4px 16px !important;

    box-shadow: none !important;
    border: 1px solid lightgray;

    mat-card-header {
      margin-bottom: 15px;
      font-size: 1.4em;
      border-bottom: 1px solid darkgray;

      .mat-card-header-text {
        margin: 0 !important;
      }

    }
  }

  .ag-row-hover {
    // background-color: #c1c1c1 !important;
  }

  .multiline-tooltip {
    font-size: 12px;
    white-space: pre-line;
  }

  .special-filter-tooltip {
    margin-left: -100px !important;
    max-width: 200px !important;
  }

  .fill {
    flex: 1 1 auto;
  }

  [hidden] {
    visibility: hidden !important;
    padding: 0 !important;
    margin: 0 !important;
    height: 0 !important;
  }

  .hidden {
    visibility: hidden !important;
  }

  .vit-red {
    color: red !important;
  }

  .vit-green {
    color: rgb(65, 207, 60) !important;
  }


  .vit-blue {
    color: rgb(76, 152, 214) !important;
  }

  .vit-bold {
    font-weight: bold !important;
  }

  .ag-cell {
    border-right: 1px solid #d9dcde !important;
  }

  .ag-group-child-count {
    // otherwise there is a space to the right of the station id column
    display: none !important;
  }

  .ag-ltr .ag-row-group-leaf-indent {
    margin-left: 0 !important;
  }

  .bold {
    font-weight: bold !important;
  }

  .text-align-right {
    text-align: right !important;
  }

  .align-center {
    justify-content: center;
    display: flex;
  }

  .right-info {
    right: 8px;
    position: absolute;
  }
}

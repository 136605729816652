@use '@angular/material' as mat;
// Plus imports for other components in your app.

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
// TODO(v15): As of v15 mat.legacy-core no longer includes default typography styles.
//  The following line adds:
//    1. Default typography styles for all components
//    2. Styles for typography hierarchy classes (e.g. .mat-headline-1)
//  If you specify typography styles for the components you use elsewhere, you should delete this line.
//  If you don't need the default component typographies but still want the hierarchy styles,
//  you can delete this line and instead use:
//    `@include mat.legacy-typography-hierarchy(mat.define-legacy-typography-config());`
@include mat.all-legacy-component-typographies();
@include mat.legacy-core();


// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://www.google.com/design/spec/style/color.html

$default-app-primary: mat.define-palette(mat.$grey-palette);
$default-app-accent: mat.define-palette(mat.$blue-palette, A200, A100, A400);
$default-app-warn: mat.define-palette(mat.$red-palette);

$default-app-theme: mat.define-light-theme(
    (
      color: (
        primary: $default-app-primary,
        accent: $default-app-accent,
        warn: $default-app-warn,
      )
    ));
$blue-gray-app-accent: mat.define-palette(mat.$blue-gray-palette, A200, A100, A400);
$blue-gray-app-theme: mat.define-light-theme(
    (
      color: (
        primary: $default-app-primary,
        accent: $blue-gray-app-accent,
        warn: $default-app-warn,
      )
    ));

$mat-mohio: (
  50: #6f2235,
  100: #6f2235,
  200: #6f2235,
  300: #6f2235,
  400: #6f2235,
  500: #6f2235,
  600: #6f2235,
  700: #6f2235,
  800: #6f2235,
  900: #6f2235,
  A100: #6f2235,
  A200: #6f2235,
  A400: #6f2235,
  A700: #6f2235,
  contrast: (
    50: rgba(black, 0.87),
    100: rgba(black, 0.87),
    200: rgba(black, 0.87),
    300: rgba(black, 0.87),
    400: rgba(black, 0.87),
    500: rgba(black, 0.87),
    600: white,
    700: white,
    800: white,
    900: white,
    A100: rgba(black, 0.87),
    A200: rgba(black, 0.87),
    A400: rgba(black, 0.87),
    A700: white,
  )
);

$deep-orange-app-accent: mat.define-palette($mat-mohio, A200, A100, A400);
$deep-orange-app-theme: mat.define-light-theme(
    (
      color: (
        primary: $default-app-primary,
        accent: $deep-orange-app-accent,
        warn: $default-app-warn,
      )
    ));

$mat-mk: (
  50: #2E4162,
  100: #2E4162,
  200: #2E4162,
  300: #2E4162,
  400: #2E4162,
  500: #2E4162,
  600: #2E4162,
  700: #2E4162,
  800: #2E4162,
  900: #2E4162,
  A100: #2E4162,
  A200: #2E4162,
  A400: #2E4162,
  A700: #2E4162,
  contrast: (
    50: rgba(black, 0.87),
    100: rgba(black, 0.87),
    200: rgba(black, 0.87),
    300: rgba(black, 0.87),
    400: rgba(black, 0.87),
    500: rgba(black, 0.87),
    600: white,
    700: white,
    800: white,
    900: white,
    A100: rgba(black, 0.87),
    A200: rgba(black, 0.87),
    A400: rgba(black, 0.87),
    A700: white,
  )
);

$mk-app-accent: mat.define-palette($mat-mk, A200, A100, A400);
$mk-app-theme: mat.define-light-theme(
    (
      color: (
        primary: $default-app-primary,
        accent: $mk-app-accent,
        warn: $default-app-warn,
      )
    ));

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.

::ng-deep .theme-default {
  @include mat.all-legacy-component-themes($default-app-theme);
}

::ng-deep .theme-deep-orange {
  $app-accent: $deep-orange-app-accent;
  @include mat.all-legacy-component-themes($deep-orange-app-theme);

  .mat-list-option:hover, .mat-list-option:focus, .mat-nav-list .mat-list-item:hover, .mat-nav-list .mat-list-item:focus,
  .mat-action-list .mat-list-item:hover, .mat-action-list .mat-list-item:focus {
    background-color: mat.get-color-from-palette($app-accent, A400, 0.3) !important;
  }

  .mat-list-option.selected, .mat-list-option.selected, .mat-nav-list .mat-list-item.selected, .mat-nav-list .mat-list-item.selected,
  .mat-action-list .mat-list-item.selected, .mat-action-list .mat-list-item.selected {
    background-color: mat.get-color-from-palette($app-accent, A400) !important;
  }

  .mat-flat-button.mat-accent, mat-toolbar.mat-accent {
    color: white !important;
  }
}

::ng-deep .theme-blue-gray {
  $app-accent: $blue-gray-app-accent;
  @include mat.all-legacy-component-themes($blue-gray-app-theme);

  .mat-list-option:hover, .mat-list-option:focus, .mat-nav-list .mat-list-item:hover, .mat-nav-list .mat-list-item:focus,
  .mat-action-list .mat-list-item:hover, .mat-action-list .mat-list-item:focus {
    background-color: mat.get-color-from-palette($app-accent, A400, 0.3) !important;
  }
}

::ng-deep .theme-mk {
  $app-accent: $mk-app-accent;
  @include mat.all-legacy-component-themes($mk-app-theme);

  .mat-list-option:hover, .mat-list-option:focus, .mat-nav-list .mat-list-item:hover, .mat-nav-list .mat-list-item:focus,
  .mat-action-list .mat-list-item:hover, .mat-action-list .mat-list-item:focus {
    background-color: mat.get-color-from-palette($app-accent, A400, 0.3) !important;
  }

  .mat-list-option.selected, .mat-list-option.selected, .mat-nav-list .mat-list-item.selected, .mat-nav-list .mat-list-item.selected,
  .mat-action-list .mat-list-item.selected, .mat-action-list .mat-list-item.selected {
    background-color: mat.get-color-from-palette($app-accent, A400) !important;
  }

  .mat-flat-button.mat-accent, mat-toolbar.mat-accent {
    color: white !important;
  }
}

@import './shared';

$font-family: Roboto, "Helvetica Neue", sans-serif;

@import "~ag-grid-community/styles/ag-grid.css";
@import "~ag-grid-community/styles/ag-theme-balham.css";

/* Hide HTML5 Up and Down arrows. */
::ng-deep input[type="number"]::-webkit-outer-spin-button, ::ng-deep input[type="number"]::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

::ng-deep input[type="number"] {
  -moz-appearance: textfield;
}

::ng-deep .edit-actions-menu-dialog .mat-menu-content {
  display: flex;
  flex-flow: column;
  padding: 8px;
}
